import React, { useState } from "react";
import "../css-styles/AvisClients.scss";
import Image1 from "../images/Pages/BenedicteetFabrice.webp";
import Image2 from "../images/Pages/decorationpanneaubienvenue-laboinspi.webp";

const AvisClients = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Liste des avis clients
  const avisClients = [
    {
      id: 1,
      content: "Une prestation au top ! Marine est très douée, sérieuse et son travail a été apprécié par tous nos invités. Vous pouvez la faire travailler en toute confiance.",
      client: "Bénédicte & Fabrice",
      rating: 5, 
      image: Image1
    },
    {
      id: 2,
      content: "Très satisfaite des services de Marine (Labo Inspi) pour notre mariage, le rendu final était parfait. Merci !",
      client: "Alexia & Nicolas",
      rating: 5,
      image:  Image2
    },
    {
      id: 3,
      content: "Marine a su créer une atmosphère magique pour notre mariage. Tout était parfait du début à la fin !",
      client: "Sophie",
      rating: 5,
      image: '/path/to/image3.jpg'
    },
    // Ajoute d'autres avis ici
  ];

  // Fonction pour changer l'avis affiché
  const goToPrevious = () => {
    const newIndex = currentIndex === 0 ? avisClients.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const newIndex = currentIndex === avisClients.length - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <div className="Avis-wrapper">
      <h2>Vos témoignages</h2>

      <div className="Avis-container">
        <button className="prev-btn" onClick={goToPrevious}>
          &lt;
        </button>

        <div className="AvisClient-element">
          <img src={avisClients[currentIndex].image} alt="Image décoration de l'avis client" className="client-image" />
          <div className="avis-content">
            <h3>{avisClients[currentIndex].client}</h3>
            <p className="fade-in">
              {avisClients[currentIndex].content}
            </p>
            <div className="rating">
              {'★'.repeat(avisClients[currentIndex].rating)}
            </div>
          </div>
        </div>

        <button className="next-btn" onClick={goToNext}>
          &gt;
        </button>
      </div>
    </div>
  );
};

export default AvisClients;
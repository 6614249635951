import React, { useState, useEffect } from 'react';
import NavigationBar from './NavigationBar'; // Assurez-vous que le chemin d'importation est correct
import '../css-styles/Header.scss'; // Assurez-vous que le chemin d'importation est correct
import { Link, useLocation } from 'react-router-dom';
import logoLabo from '../images/icone/icone-labo.webp';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [showPromo, setShowPromo] = useState(false); // État pour gérer l'affichage décalé de la promo-header
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0); // État pour gérer l'index du message promo
  const location = useLocation();

  const promoMessages = [
    {
      message: "Réservez avant la fin de l'année et bénéficiez de 10% de réduction sur votre pack mariage complet !",
      hasButton: true // Ce message aura un bouton
    },
    {
      message: "Réservez votre consultation gratuite pour discuter de votre vision de mariage avec notre expert en décoration !",
      hasButton: false // Ce message n'a pas besoin d'un bouton
    }
  ];

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
        setTimeout(() => {
          setShowPromo(true);
        }, 1000);
      } else {
        setIsScrolled(false);
        setShowPromo(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (showPromo) {
      // Changer de message toutes les 5 secondes (5000 ms)
      const interval = setInterval(() => {
        setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % promoMessages.length);
      }, 7000);
      return () => clearInterval(interval); // Nettoie l'intervalle lorsque le composant est démonté
    }
  }, [showPromo, promoMessages.length]);

  return (
    <header className={`header ${(isScrolled || (location.pathname !== '/' && location.pathname !== '/Coaching' && location.pathname !== '/Preparatifs' && location.pathname !== '/Cle-en-main' && location.pathname !== '/Blog')) ? 'scrolled' : ''}`}>
      <div className='wrapper-header'>
        <Link to="/">
          <img src={logoLabo} className="Laboinspi-logo" alt="Lab-o-inspi-logo" />
        </Link>
        {isScrolled && showPromo && (
          <div className="promo-header">
            <div className="promo-content">
              {/* Affiche le message promo en fonction de l'index actuel */}
              <p className="promo-message">{promoMessages[currentMessageIndex].message}</p>
              {/* Inclure le bouton seulement pour le premier message */}
              {promoMessages[currentMessageIndex].hasButton && (
                <a href="#contact" className="promo-btn">Réservez</a>
              )}
            </div>
          </div>
        )}
        <NavigationBar />
      </div>
    </header>
  );
}

export default Header;
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';

import SecondArticleContent from '../components/SecondArticleContent';

const SecondArticlePage = () => {
  return (
    <div className="home-galerie">
      <div className="home-container">
        <Helmet>
          {/* Vos métadonnées ici */}
        </Helmet>
        <Header />
        <SecondArticleContent />
        {/* Contenu de votre article ici */}
        <Footer />
      </div>
    </div>
  );
};

export default SecondArticlePage;
import React from 'react';
import { Helmet } from 'react-helmet';
import '../css-styles/DecorationPage.scss';
import Image2 from '../images/gallery/anais-site_2024-01-13_1317/ceremonielaique-elopementbohocoloré-anaisduchesnephotographie1.webp';
import Header from "../components/Header.js";
import CatalogPreview from '../components/CatalogPreview.js';
import { AiOutlineCheckCircle } from "react-icons/ai";

const DecorationPage = () => {
  return (
    <div className="decoration-page">
       <Helmet>
          <title>La décoration de mariage qui vous convient</title>
          <meta name="description" content="Formule décoration, installation et location pour votre évènement de mariage." />
          <link rel="canonical" href="https://laboinspi-wedding.fr/Decoration" />
        </Helmet>
      <Header />
      <h1 className="decoration-title">Votre décoration de mariage</h1>
      <p className="decoration-subtitle">
        Imaginons et créons ensemble une décoration originale et sur-mesure
      </p>

      {/* Bloc Texte et Image 1 */}
      <section className="decoration-section">
        <div className="decoration-text">
          <p className='decoration-text-up'>
            Je vous accompagne et vous conseille dans la création d'une scénographie unique pour votre mariage.
            Je vous propose des <span className="highlight">objets de décoration modernes</span> et 
            <span className="highlight"> élégants</span> directement livrés et installés sur votre lieu de réception.
          </p>
        </div>
      </section>

      {/* Bloc Texte et Image 2 avec Formule */}
      <section className="decoration-section reverse">
        <div className="decoration-formula">
          <h2 className="decoration-subheading">Formule Installation</h2>
          <div className="decoration-formula-content">
            <ul>
              <li><AiOutlineCheckCircle className="icon" /> Un RDV Déco à distance</li>
              <li><AiOutlineCheckCircle className="icon" /> Création d'un moodboard et d’un book inspiration</li>
              <li><AiOutlineCheckCircle className="icon" /> Élaboration de la shopping list</li>
              <li><AiOutlineCheckCircle className="icon" /> Conseils et accompagnement dans le choix des objets</li>
              <li><AiOutlineCheckCircle className="icon" /> Livraison et installation de la décoration la veille</li>
              <li><AiOutlineCheckCircle className="icon" /> Récupération du matériel rangé le dimanche ou lundi</li>
            </ul>
            <p className="price-service-description">À partir de 350 €</p>
            <p className="decoration-note">
              *Ce tarif n’inclue pas la location du matériel, la désinstallation et les frais kilométriques de déplacement liés à votre événement.
            </p>
          </div>
        </div>
        <div className="decoration-image">
          <img src={Image2} alt="Décoration 2" />
        </div>
      </section>

      {/* Texte supplémentaire */}
      <section className="decoration-extra-text">
        <p>
          En faisant appel au Labo’ Inspi, vous disposez d’une large gamme <span className="highlight">d’objets de décoration</span> disponible à la location pour sublimer votre lieu de réception : panneau de bienvenue, plan de table, espace urne, photobooth, décoration de table, décoration de salle, lumières...
        </p>
        <CatalogPreview />
        <div className='catalogue-button-wrapper'>
          <a href="/Catalogue" className="catalogue-button">Découvrir le catalogue</a>
        </div>
      </section>
    </div>
  );
}

export default DecorationPage;
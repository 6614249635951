import React from 'react';
import Image1 from '../images/Pages/Photo papeterie.webp'; // Remplace par le bon chemin d'image

import '../css-styles/Papeterie.scss';

const Papeterie = () => {
  return (
    <div className="papeterie-wrapper">
      <div className="papeterie-text">
        <h2>Papeterie</h2>
        <p>
          Un papeterie sur mesure dans un style <span className="highlight">élégant</span> et <span className="highlight">minimaliste</span> pour votre mariage.
        </p>
        <p>
          Conception et <span className="highlight">création de votre papeterie du jour J</span> pour tous vos supports de décoration : panneau de bienvenue, plan de table, marque-place, menu...
        </p>
        <a href="/Papeterie" className="mes-creations-button">Mes créations</a>
      </div>
      
      <div className="papeterie-images-home">
        <div className="image-row">
          <img src={Image1} alt="Papeterie décoration 1" className="papeterie-image-element" />
        </div>
      </div>
    </div>
  );
}

export default Papeterie;
import React from 'react';
import { Helmet } from 'react-helmet';
import '../css-styles/PapeteriePage.scss';
import Image1 from '../images/Pages/papeteriemariage-laboinspi-anaisduchesnephotographie.jpg';
import Image2 from '../images/Pages/panneaubienvenue-decorationmariage-laboinspi.jpg';
import Image3 from '../images/Pages/plandetable-decorationmariage-laboinspi.jpg';
import Header from '../components/Header';
import Footer from '../components/Footer';

const PapeteriePage = () => {
  return (
    <div className="home-catalogue">
        <Helmet>
        <title>Papeterie de mariage sur mesure</title>
        <meta name="description" content="Des papeteries personnalisées pour votre mariage et vos décorations." />
        <link rel="canonical" href="https://laboinspi-wedding.fr/Papeterie" />
      </Helmet>
        <Header />
      <div className="home-container">
    <div className="papeterie-page">
      <h1 className="papeterie-title">UNE PAPETERIE DE MARIAGE SUR MESURE</h1>
      <h2 className="papeterie-subtitle">Imaginons et créons ensemble tous vos éléments de papeterie</h2>
      
      <div className="papeterie-content">
        {/* Bloc de gauche avec image et texte */}
        <div className="papeterie-block">
            <div className='papeterie-image-wrapper'>
                <img src={Image1} alt="Papeterie image 1" className="papeterie-image"/>
                <div className='papeterie-frame'></div>
            </div>
          
          <div className="papeterie-text">
            <h3>UNE PAPETERIE À LA CARTE</h3>
            <p>L’élégance au bout des doigts</p>
            <p>
              Rien de tel que de belles invitations pour donner le ton de votre mariage. Du faire-part au marque-place, chaque élément est pensé dans un style minimaliste, avec des designs épurés et raffinés, créés spécialement pour vous.
            </p>
            <p>
              Papier texture, typographies modernes et finitions élégantes : chaque détail est soigné pour refléter à la perfection l’ambiance de votre mariage.
            </p>
          </div>
        </div>

        {/* Bloc de droite avec image et texte */}
        <div className="papeterie-block2">
          <div className="papeterie-text">
            
            <h3>DES STICKERS PERSONNALISÉS</h3>
            <p>La petite touche qui fait la différence</p>
            
             <p>
              Envie d’une décoration unique jusque dans les moindres détails ? Grâce à la technologie Cricut, je crée des stickers personnalisés qui sublimeront vos panneaux de bienvenue, plan de table, urne, ou même votre papeterie.
            </p>
             <p>
              Typographie, couleurs… tout est réalisé selon vos envies et ces petites touches délicates et élégantes apporteront un charme supplémentaire à votre décoration.
            </p>
          </div>
          <div className='papeterie-image-wrapper2'>
            <img src={Image2} alt="Papeterie image 2" className="papeterie-image"/>
            <div className='papeterie-frame2'></div>
          </div >
          
        </div>

        {/* Bloc bas avec image et texte */}
        <div className="papeterie-block3">
          <img src={Image3} alt="Papeterie image 3" className="papeterie-image"/>
          <div className="papeterie-text">
            <h3>CALLIGRAPHIE</h3>
            <p>Un art unique fait à la main</p>
            <p>
              Pour un effet vraiment original et personnalisé, optez pour la calligraphie sur supports. Que ce soit sur du verre, des toiles ou même du papier, je réalise à la main chaque nom, message ou détail avec une calligraphie fine et sophistiquée.
            </p>
            <p>
              C’est l’option idéale pour des marque-places uniques, ou encore des panneaux d’accueil qui impressionneront vos invités par leur authenticité et leur élégance.
            </p>
          </div>
        </div>
      </div>

      <div className="cta-button-wrapper">
        <button className="cta-button">MA DEMANDE</button>
      </div>
    </div>
    </div>
    <Footer />
    </div>
  );
};

export default PapeteriePage;
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import './css-styles/index.css';
import Home from './pages/Home';

import Contact from './pages/Contact';

import FirstFormule from './pages/FirstFormule';
import SecondeFormule from './pages/SecondeFormule';
import ThirdFormule from './pages/ThirdFormule';
import Apropos from './pages/Apropos';
import Error from './pages/Error';
import Blog from './pages/Blog';


import ArticlePage from './pages/ArticlePage';
import SecondArticlePage from './pages/SecondArticlePage';
import CatalogueDeco from './pages/Catalogue';
import Galerie from './pages/Galerie';
import WeddingDesign from './pages/WeddingDesign';
import DecorationPage from './pages/DecorationPage';
import PapeteriePage from './pages/PapeteriePage';


createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Error />} />
        
        <Route path="/WeddingDesign" element={<WeddingDesign/>} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Blog" element={<Blog />} />
        <Route path="/Votre-Decoratrice" element={<Apropos/>} />
        <Route path="/Catalogue" element={<CatalogueDeco/>} />
        <Route path="/Galerie" element={<Galerie/>} />
        <Route path="/Decoration" element={<DecorationPage/>} />
        <Route element={<Navigate to="/" />} /> 
        <Route path="/Papeterie" element={<PapeteriePage/>}/>

        <Route path="/Coaching" element={<FirstFormule/>}/>
        <Route path="/Preparatifs" element={<SecondeFormule/>}/>
        <Route path="/Cle-en-main" element={<ThirdFormule/>}/>


        <Route path="/Metier-wedding-designer" element={<ArticlePage />} />
        <Route path="/Mon-parcours-wedding-designer" element={<SecondArticlePage />} />
      </Routes>
    </Router>
  </React.StrictMode>
);

import React, { useEffect, useRef, useState } from 'react';
import Image from '../images/Pages/décorationphotobooth-location-laboinspi.webp';
import ImageSmall from '../images/Pages/décorationphotobooth-location-laboinspiSmall.webp';
import '../css-styles/WeddingDesigner.scss';

const WeddingDesigner = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const imageRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    const handleScroll = () => {
      if (imageRef.current) {
        const rect = imageRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;
        setIsVisible(rect.top <= windowHeight && rect.bottom >= 0);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Check on initial render
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const currentImage = windowWidth <= 576 ? ImageSmall : Image;

  return (
    <div className='designer-wrapper'>
      <div className='photo-container'>
          <img
            ref={imageRef}
            src={currentImage}
            className={`photo-table ${isVisible ? 'visible' : ''}`}
            alt='salleshootingdolcevita-sarahduguephotographe4'
          />
        </div>
      <div className='designer-container'>
        <h2 className='element-title'>Wedding Design</h2>
        
        <div className='designer-text'>
          <p>Un accompagnement personnalisé et des conseils pour créer la <span className="colored-text">scénographie unique </span> pour votre mariage.</p>
          <p>En collaboration avec un réseau de <span className="colored-text">partenaires qualifiés</span>, nous concevons un projet complet pour célébrer votre union : fleurs, papeterie, décoration, lumières...</p>
        </div>
        <a href="/WeddingDesign" className="mon-metier-button">Mon Métier</a> {/* Bouton stylisé */}
      </div>
    </div>
  );
}

export default WeddingDesigner;
import React, { useEffect } from 'react';
import image2 from '../images/Separateur.png';
import '../css-styles/Title.scss';
import Image1 from '../images/Pages/décorationcérémonielaique-anaisduchesnephotographie.webp';
import ImageSmall from '../images/Pages/décorationcérémonielaique-anaisduchesnephotographieSmall.webp';

const Title = () => {
    useEffect(() => {
        const img = new Image();
        img.src = ImageSmall;
    }, []);

    return (
        <div>
            <div className='hero-background'>
                <img src={window.innerWidth <= 576 ? ImageSmall : Image1} className="Background-photographie" alt="photographie-couple-mariage-dolcevita" loading="eager" />
            </div>
            <div className='title-wrapper'>
                <h1 className='title-main'>Une décoration sur mesure <br /> pour vos événements</h1>
                <h2 className='signature-wordkey'> Décoratrice et Wedding Designer sur Pornic et Nantes</h2>
                <a href="/WeddingDesign" className="discover-button">DÉCOUVRIR</a> {/* Le bouton "Découvrir" */}
            </div>     
        </div>
    );
}

export default Title;
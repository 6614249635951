import React, { useState } from "react";
import axios from "axios";
import "../css-styles/FormContactHomePage.scss"; 

const FormContactHomePage = () => {
  const [formData, setFormData] = useState({
    name: "",
    firstname: "",
    email: "",
    message: "",
  });

  // Gestion des changements dans les champs du formulaire
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // Gestion de la soumission du formulaire
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('https://laboinspi-wedding.fr/api/formulaires/envoyer-formulaire', formData);
      console.log('Réponse du serveur:', response.data);
    } catch (error) {
      console.error('Erreur lors de la soumission du formulaire :', error);
    }
  };

  return (
    <div className="homepage-form-container">
      <h2 className="homepage-form-title">Contact</h2>
      <form className="Form-HomePage" onSubmit={handleSubmit}>
        <div className="homepage-form-input-line">
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Nom"
          />
        </div>
        <div className="homepage-form-input-line">
          <input
            type="text"
            id="firstname"
            name="firstname"
            value={formData.firstname}
            onChange={handleChange}
            placeholder="Prénom"
          />
        </div>
        <div className="homepage-form-input-line">
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="E-mail"
          />
        </div>
        <div className="homepage-message-form">
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            placeholder="Message"
          />
        </div>
        <button type="submit" className="homepage-submit-button">
          Envoyer
        </button>
      </form>
    </div>
  );
};

export default FormContactHomePage;
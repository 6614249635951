import React, { useState, useEffect, forwardRef } from 'react';
import Image from "../images/Pages/Marine Philippe- Morgane Caulle-9.webp";
import ImageSmall from "../images/Pages/Marine Philippe- Morgane Caulle-9.webp";
import '../css-styles/MarineBio.scss';
import App from './App';

const MarineBio = () => {


  return (
    <div className='Bio-full-wrapper'>
      <h2>Votre décoratrice sur Pornic et Nantes</h2>
      <div className='bio-wrapper'>
      <img src={window.innerWidth <= 576 ? ImageSmall : Image} className="portrait" alt="Marine_wedding designer_loireatlatantique-photographie Morgan Caulle" loading='eager'/>
      <div className='texte-bio'>

        <div className='typewriter-conteneur'>
        <App />
        </div>
        <p> <span className="colored-text"> Passionnée de décoration</span> mais aussi romantique dans l’âme, j’ai toujours adoré créer des décors pour toutes les occasions. </p>
        <p> Cette aventure en tant que <span className="colored-text">décoratrice</span> et <span className="colored-text">Wedding Designer</span>.<br>
        </br>  c’est faire le métier dont je rêvais depuis toujours.</p>
        <a href="/Votre-Decoratrice" className="mon-histoire-button">Mon histoire</a>
      </div>
    </div>
    </div>
    
  );
};

export default MarineBio;
import React, { useState } from 'react';
import Slider from 'react-slick';
import IMAGES from './ImageGallery'; // Importez votre liste d'images
import '../css-styles/GalerieComponent.scss'; // Assurez-vous que ce chemin est correct

const GalerieComponent = () => {
  const [selectedCategory, setSelectedCategory] = useState('Toutes');
  const [activeIndex, setActiveIndex] = useState(0); // Suivi de l'image active

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    centerMode: true,
    centerPadding: '0',
    arrows: true,
    beforeChange: (current, next) => setActiveIndex(next), // Mise à jour de l'index actif
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  // Fonction pour filtrer les images selon la catégorie sélectionnée
  const getFilteredImages = () => {
    if (selectedCategory === 'Toutes') {
      return IMAGES;
    }
    return IMAGES.filter(image => image.category === selectedCategory);
  };

  const filteredImages = getFilteredImages();

  const getPreviewImages = () => {
    const totalImages = filteredImages.length;
    const prevImages = [];
    const nextImages = [];

    for (let i = 1; i <= 3; i++) {
      prevImages.push(filteredImages[(activeIndex - i + totalImages) % totalImages]);
      nextImages.push(filteredImages[(activeIndex + i) % totalImages]);
    }

    return {
      prev: prevImages.reverse(), // Inverser pour que les images précédentes soient dans l'ordre correct
      active: filteredImages[activeIndex],
      next: nextImages,
    };
  };

  const previewImages = getPreviewImages();

  return (
    <div className="gallery-container">
      {/* Sélecteur de catégories */}
      <div className="category-selector">
        {['Toutes', 'Salle réception', 'Décor floral', 'Papeterie & Signalétique', 'Cérémonie laïque', 'Bar à thème & wedding cake', 'Photobooth & Espace Lounge'].map(category => (
          <button
            key={category}
            onClick={() => setSelectedCategory(category)}
            className={selectedCategory === category ? 'active' : ''}
          >
            {category}
          </button>
        ))}
      </div>

      {/* Carrousel d'images */}
      <Slider {...settings}>
        {filteredImages.map((image, index) => (
          <div key={index} className="carousel-item">
            <img
              src={image.src}
              alt={image.caption}
              style={{ width: 'auto', height: '600px', objectFit: 'cover' }}
              loading="lazy"
            />
            <div className="Text-description-gallery">
              <p>{image.caption}</p>
              <p className="credit-text">{image.credit}</p>  
            </div>
          </div>
        ))}
      </Slider>

      {/* Preview des images */}
      <div className="preview-container">
        {previewImages.prev.map((image, index) => (
          <img
            key={index}
            src={image.minSrc} 
            className="preview-image"
            alt={image.caption}
            style={{ filter: 'brightness(0.5)' }} // Images précédentes avec filtre assombri
          />
        ))}
        <img
          src={previewImages.active.minSrc} 
          className="preview-image active"
          alt={previewImages.active.caption}
          style={{ filter: 'brightness(1.4)' }} // Image active sans filtre
        />
        {previewImages.next.map((image, index) => (
          <img
            key={index}
            src={image.minSrc} 
            className="preview-image"
            alt={image.caption}
            style={{ filter: 'brightness(0.5)' }} // Images suivantes avec filtre assombri
          />
        ))}
      </div>
    </div>
  );
};

// Flèche suivante
const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        right: '10px',
        zIndex: '1',
        width: '40px',
        height: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
      }}
      onClick={onClick}
    />
  );
};

// Flèche précédente
const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        background: 'black',
        left: '10px',
        zIndex: '1',
        width: '40px',
        height: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
      }}
      onClick={onClick}
    />
  );
};

export default GalerieComponent;
import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../css-styles/NavigationBar.scss';

const NavigationBar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();
  const menuRef = useRef(null); // Référence pour le menu

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const isRouteActive = (route) => location.pathname === route;

  const activeLinkClass = "active-link";

  // Gestion du clic en dehors du menu pour le fermer
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMobileMenu(); // Ferme le menu si clic en dehors
      }
    };

    // Ajoute l'écouteur d'événements
    document.addEventListener('mousedown', handleClickOutside);

    // Nettoie l'écouteur d'événements lors de la destruction du composant
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  return (
    <nav className="nav-bar" ref={menuRef}>
      <div className="burger-button" onClick={toggleMobileMenu}>
        <div className={`burger-icon ${isMobileMenuOpen ? 'open' : ''}`}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </div>

      <ul className={`main-menu ${isMobileMenuOpen ? 'open' : ''}`}>
        <li>
          <Link to="/" className={isRouteActive('/') ? activeLinkClass : ''} onClick={closeMobileMenu}>Accueil</Link>
        </li>
       
        <li>
          <Link to="/WeddingDesign" className={isRouteActive('/WeddingDesign') ? activeLinkClass : ''} onClick={closeMobileMenu}>Wedding Design</Link>
        </li>
        <li>
          <ul className="sub-menu">
            <li><Link to="/Coaching" className={isRouteActive('/Coaching') ? activeLinkClass : ''} onClick={closeMobileMenu}>Coaching</Link></li>
            <li><Link to="/Preparatifs" className={isRouteActive('/Preparatifs') ? activeLinkClass : ''} onClick={closeMobileMenu}>Préparatifs</Link></li>
            <li><Link to="/Cle-en-main" className={isRouteActive('/Cle-en-main') ? activeLinkClass : ''} onClick={closeMobileMenu}>Clé en main</Link></li>
          </ul>
        </li>
        <li>
          <Link to="/Decoration" className={isRouteActive('/Decoration') ? activeLinkClass : ''} onClick={closeMobileMenu}>Décoration</Link>
        </li>
        <li>
          <ul className="sub-menu">
            <li><Link to="/Catalogue" onClick={closeMobileMenu}>Location Matériel</Link></li>
            <li><Link to="/Papeterie" onClick={closeMobileMenu}>Papeterie</Link></li>
          </ul>
        </li>
        <li>
          <Link to="/Galerie" className={isRouteActive('/Galerie') ? activeLinkClass : ''} onClick={closeMobileMenu}>Galerie Photo</Link>
        </li>
        <li>
          <Link to="/Blog" className={isRouteActive('/Blog') ? activeLinkClass : ''} onClick={closeMobileMenu}>Blog</Link>
        </li>
        <li>
          <Link to="/Votre-Decoratrice" className={isRouteActive('/VotreDecoratrice') ? activeLinkClass : ''} onClick={closeMobileMenu}>Votre décoratrice</Link>
        </li>
        <li>
          <Link to="/Contact" className={isRouteActive('/Contact') ? activeLinkClass : ''} onClick={closeMobileMenu}>Contact</Link>
        </li>
      </ul>
    </nav>
  );
}

export default NavigationBar;
import React from "react";
import "../css-styles/AllWedding.scss";
import Image1 from "../images/formules/salleshootingdolcevita-sarahduguephotographe160.webp";
import Image5 from "../images/weddingcakeshootingdolcevita-sarahduguephotographe42.webp";
import { AiOutlineCheckCircle } from "react-icons/ai";

const AllWedding = () => {
  return (
    <div id="all-wedding" className="all-wedding">
      <div className='allWedding-banner'>
<img src={Image5}/>
      </div>
      <div className="all-title-wrapper">
       
        <div className="prepare-title-container">
          <h2 className="prepare-title">Formule Clé en Main</h2>
        </div> 
        <h3>" Immersion & Scénographie "</h3>
      </div>
      <div className="all-text">
        <p>
        Avec cette formule, vous me partagez toutes vos envies pour votre
décoration de mariage et je m’occupe de tous les préparatifs déco sans exception. Vous
profitez pleinement de votre grand jour sans stress et garder un peu de surprise !
        </p>
      </div>

      <div className="all-wedding-container">
        <div className="images-container">
          <img
            className="image-element"
            src={Image1}
            alt="Image coaching mariage"
          />
        </div>

        <div className="service-description">
  <h3>Descriptif complet du service :</h3>
  <div className="nameVertical-allWedding-listWrapper">
    <h4 className="name-vertical-allWedding">LA CONCEPTION</h4>
    <ul>
      <li>
        <AiOutlineCheckCircle className="icon" />
        <span>Un RDV découverte à distance</span>
      </li>
      <li>
        <AiOutlineCheckCircle className="icon" />
        <span>Création d'un moodboard ou planche d'inspiration</span>
      </li>
      <li>
        <AiOutlineCheckCircle className="icon" />
        <span>
          Un RDV Déco - Définition du cahier des charges concernant la
          scénographie de votre mariage : choix du thème, couleurs, matières,
          fleurs, objets, espaces déco...
        </span>
      </li>
      <li>
        <AiOutlineCheckCircle className="icon" />
        <span>
          Création d'un book complet détaillant avec précision les différents
          espaces de décoration
        </span>
      </li>
      <li>
        <AiOutlineCheckCircle className="icon" />
        <span>Conception et création de vos éléments de décoration</span>
      </li>
    </ul>
  </div>
  <div className="nameVertical-allWedding-listWrapper">
    <h4 className="name-vertical-allWedding">L’ORGANISATION</h4>
    <ul>
      <li>
        <AiOutlineCheckCircle className="icon" />
        <span>Visite technique de votre lieu de réception</span>
      </li>
      <li>
        <AiOutlineCheckCircle className="icon" />
        <span>
          Élaboration de votre shopping list : papeterie, fleurs, mobiliers &
          déco et tous les éléments nécessaires à la décoration
        </span>
      </li>
      <li>
        <AiOutlineCheckCircle className="icon" />
        <span>
          Choix des prestataires : infographiste, fleuriste, location mobiliers
          & décoration…
        </span>
      </li>
      <li>
        <AiOutlineCheckCircle className="icon" />
        <span>Conseils et accompagnement jusqu'au Jour J</span>
      </li>
    </ul>
  </div>
  <div className="nameVertical3-allWedding-listWrapper">
    <h4 className="name-vertical3-allWedding">JOUR J</h4>
    <ul>
      <li>
        <AiOutlineCheckCircle className="icon" />
        <span>
          Installation de votre décoration avec soin et créativité
        </span>
      </li>
      <li>
        <AiOutlineCheckCircle className="icon" />
        <span>Coordination de tous les prestataires déco sur place</span>
      </li>
      <li>
        <AiOutlineCheckCircle className="icon" />
        <span>Désinstallation après événement selon votre lieu de réception</span>
      </li>
    </ul>
  </div>
  <p className="price-service-description">À partir de 1500 € *</p>
  <p className="Contract-text">
    *Ce tarif n'inclut pas les frais kilométriques, la livraison du matériel sur
    place, la location et la création des éléments de décoration, l'achat de la
    papeterie et des fleurs.
  </p>
</div>


      </div>
    </div>
  );
};

export default AllWedding;

import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CatalogueComponent from '../components/CatalogueComponent';
import { Helmet } from 'react-helmet';
import "../css-styles/Catalogue.scss";

export default function Catalogue() {
  return (
    <div className="home-catalogue">
      <div className="home-container">
        <Helmet>
          <title>Catalogue de Décoration</title>
          <meta name="description" content="Découvrez notre catalogue de décoration pour vos événements." />
          <link rel="canonical" href="https://laboinspi-wedding.fr/catalogue" />
        </Helmet>
        <Header />
        <div className='title-catalogue-text'>
          <h1 className='Catalogue-title'>Catalogue de Décoration</h1>
          <h2>Explorez nos produits pour sublimer vos événements.</h2>
        </div>
        <CatalogueComponent /> 
      </div>
      <Footer />
    </div>
  );
}
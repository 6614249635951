import React from 'react';
import '../css-styles/BackgroundImage.scss';
import FormContactHomePage from './FormContactHomePage';

const BackgroundImage = () => {
  return (
    <div className="background-image-container">
      {/* Informations de contact à gauche */}
      <div className="contact-info">
        <h3>Marine Philippe - Le Labo’ Inspi</h3>
        <p>Agence Pornic - Nantes</p>
        <p>le.labo.inspi@gmail.com</p>
        <p>06 77 48 01 57</p>
      </div>

      {/* Formulaire de contact à droite */}
      <div className="form-container-homepage">
        <FormContactHomePage />
      </div>
    </div>
  );
};

export default BackgroundImage;
import React from 'react';
import { Helmet } from 'react-helmet';
import '../css-styles/WeddingDesign.scss'; 
import PreviewFormules from '../components/PreviewFormules';
import Image1 from '../images/Pages/décorationphotobooth-location-laboinspi.webp'; 
import Image2 from '../images/Pages/ceremonielaique-decorationmariage.jpg';
import FormContact from '../components/FormContact';
import Header from "../components/Header.js";

const WeddingDesign = () => {
  return (
    <div className="wedding-design-page">
      <Helmet>
        <title>Description du travail de wedding designer</title>
        <meta name="description" content="Wedding designer, Organisatrice de décoration de mariage." />
        <link rel="canonical" href="https://laboinspi-wedding.fr/WeddingDesign" />
      </Helmet>
      <Header />
      <h1>Le rôle de votre Wedding Designer</h1>
          <p className="subtitle">La Wedding Designer, chef d’orchestre de votre décoration de mariage</p>
      {/* Bloc Texte et Image 1 */}
      <section className="text-image-block">
        <div className="text">
          
          <p>En tant que décoratrice événementielle, je transforme vos idées et vos envies en un <span className="highlight">véritable univers</span> emprunt de vos personnalités pour votre mariage.  </p>
          <p>Chaque détail est soigneusement pensé pour que tout soit à la fois <span className="highlight">esthétique</span> , pratique et parfaitement harmonieux : l’agencement des espaces, les couleurs, les matières, l’éclairage... rien n’est laissé au hasard!</p>
          
          <div class="quote-container-wedding">
              <span class="quote-icon">“</span>
              <p class="quote-text">
              Je serai votre partenaire déco pour une scénographie de mariage qui vous ressemble, sans prise de tête.
              </p>
            </div>
        </div>
        <div className="image">
          <img src={Image1} alt="Décoration 1" />
        </div>
      </section>

      {/* Bloc Texte et Image 2 */}
      <section className="text-image-block2">
        <div className="image">
          <img src={Image2} alt="Décoration 2" />
        </div>
        <div className="text">
          <p>Je m’engage à vous offrir une expérience exceptionnelle qui vous reflète : de la conception de votre projet de mariage à l’installation de la décoration, y compris dans les moindres détails et avec une grande rigueur.</p>
          <p>Avec ma créativité et mon implication, je serai présente à chaque étape des préparatifs pour imaginer ensemble votre journée parfaite.</p>
        </div>
      </section>

      {/* Section formules */}
      <section className="formulas-section">
        <h2>Mes Formules en Wedding Design</h2>
        <p className="description">Des conseils et un accompagnement personnalisé <br>
        </br> pour réussir votre décoration de mariage</p>
        <p>Chacun de vos projets est unique, c’est pourquoi je vous conseille et vous accompagne dans vos choix de décoration pour un mariage à votre image.<br>
        </br>Chacune de ces 3 formules est adaptée à un besoin en tant que futur.e marié.e,
 100% personnalisable et élaborée avec amour !</p>
        <PreviewFormules />
      </section>

      {/* Section demande personnalisée */}
      <FormContact />
    </div>
  );
}

export default WeddingDesign;
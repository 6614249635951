import Image1 from '../images/catalogue/20240531_115500.webp';
import Image2 from '../images/catalogue/N table a piquer.webp';
import Image3 from '../images/catalogue/amour.webp';
import Image4 from '../images/catalogue/bougeoir blanc or.webp';
import Image5 from '../images/catalogue/bougeoir fumé.webp';
import Image6 from '../images/catalogue/bougeoir nude.webp';
import Image7 from '../images/catalogue/BenedicteetFabrice.png';
import Image8 from '../images/catalogue/soliflore bouteille.webp';
import Image9 from '../images/catalogue/soliflore coloré.webp';
import Image10 from '../images/catalogue/soliflore crystal.webp';
import Image11 from '../images/catalogue/mini arche bois.webp';
import Image12 from '../images/catalogue/soliflore fumé.webp';
import Image13 from '../images/catalogue/mr&mrs.webp';
import Image14 from '../images/catalogue/panneau demi arche.webp';
import Image15 from '../images/catalogue/plateau bois clair.webp';
import Image16 from '../images/catalogue/plateau bois ebene.webp';
import Image17 from '../images/catalogue/plateau bois manguier.webp';
import Image18 from '../images/catalogue/soliflore blanc.webp';
import Image19 from '../images/catalogue/soliflore taupe.webp';
import Image20 from '../images/catalogue/vase blanc.webp';
import Image21 from '../images/catalogue/vase serpentin.webp';
import Image22 from '../images/catalogue/soliflore rainuré.webp';
import Image23 from '../images/catalogue/soliflore rectangle.webp';
import Image24 from '../images/catalogue/bougeoire bubble.webp';
import Image25 from '../images/catalogue/bougeoir vert d eau.webp';
import Image26 from '../images/catalogue/soliflore boule.webp';
import Image27 from '../images/catalogue/soliflore boule 3.webp';
import Image28 from '../images/catalogue/vase collerette.webp';
import Image29 from '../images/catalogue/vase coupe.webp';
import Image30 from '../images/catalogue/vase collerette2.webp';
import Image31 from '../images/catalogue/love.webp';
import Image32 from '../images/catalogue/console en bois.webp';

const PRODUCTS = [
  {
    id: 1,
    category: 'Panneaux et Backdrop',
    name: 'Panneau en bois clair',
    imageSrc: Image1,
    description: 'Panneau en bois clair pour message de bienvenue.',
    price: '35 €',
    dimensions: '80 x 120 cm'
  },
  {
    id: 2,
    category: 'Panneaux et Backdrop',
    name: 'Table à piquer',
    imageSrc: Image2,
    description: 'Table à piquer en bois pour centre de table.',
    price: '2 €',
    dimensions: '25 cm de hauteur'
  },
  {
    id: 3,
    category: 'Panneaux et Backdrop',
    name: 'Amour panneau bois',
    imageSrc: Image3,
    description: 'Panneau en bois avec le mot "Amour".',
    price: '6 €',
    dimensions: '35 x 10 cm'
  },
  {
    id: 4,
    category: 'Bougeoirs et Photophores',
    name: 'Bougeoir blanc or',
    imageSrc: Image4,
    description: 'Bougeoir en céramique couleur blanc et or.',
    price: '2 €',
    dimensions: 'Hauteurs : 10,5 et 14 cm'
  },
  {
    id: 5,
    category: 'Bougeoirs et Photophores',
    name: 'Bougeoir fumé',
    imageSrc: Image5,
    description: 'Bougeoir en verre fumé, moderne.',
    price: '3 €',
    dimensions: 'Hauteurs : 12 et 22 cm'
  },
  {
    id: 6,
    category: 'Bougeoirs et Photophores',
    name: 'Bougeoir nude',
    imageSrc: Image6,
    description: 'Bougeoir en céramique, couleur crème et pêche.',
    price: '2 €',
    dimensions: 'Hauteurs : 6 et 13 cm'
  },
  {
    id: 7,
    category: 'Panneaux et Backdrop',
    name: 'Panneau chevalet',
    imageSrc: Image7,
    description: 'Panneau en palette bois ébène pour message de bienvenue.',
    price: '40 €',
    dimensions: '60 x 100 cm'
  },
  {
    id: 8,
    category: 'Vases et Soliflores',
    name: 'Soliflore bouteille',
    imageSrc: Image8,
    description: 'Vase cylindre lisse, soliflore.',
    price: '1 €',
    dimensions: 'Hauteur : 13 cm'
  },
  {
    id: 9,
    category: 'Vases et Soliflores',
    name: 'Soliflore coloré',
    imageSrc: Image9,
    description: 'Vase en verre coloré, vert, jaune et rose.',
    price: '1 €',
    dimensions: 'Hauteurs : de 8,5 à 13,5 cm'
  },
  {
    id: 10,
    category: 'Vases et Soliflores',
    name: 'Soliflore crystal',
    imageSrc: Image10,
    description: 'Petit vase effet crystal.',
    price: '1 €',
    dimensions: 'Hauteur : 15 cm'
  },
  {
    id: 11,
    category: 'Panneaux et Backdrop',
    name: 'Mini arche bois',
    imageSrc: Image11,
    description: 'Mini arche en bois ébène, personnalisable.',
    price: '1 €',
    dimensions: '12 x 9,5 cm'
  },
  {
    id: 12,
    category: 'Vases et Soliflores',
    name: 'Soliflore fumé',
    imageSrc: Image12,
    description: 'Vase soliflore en verre fumé.',
    price: '1 €',
    dimensions: 'Hauteur : 9,5 cm'
  },
  {
    id: 13,
    category: 'Panneaux et Backdrop',
    name: 'Mr & Mrs',
    imageSrc: Image13,
    description: 'Panneau en bois clair "Mr & Mrs".',
    price: '4 €',
    dimensions: '26 x 7 cm'
  },
  {
    id: 14,
    category: 'Panneaux et Backdrop',
    name: 'Panneau demi arche',
    imageSrc: Image14,
    description: 'Panneau demi-arche en bois clair pour message de bienvenue.',
    price: '30 €',
    dimensions: '60 x 120 cm'
  },
  {
    id: 15,
    category: 'Supports et Décoration de Table',
    name: 'Plateau bois clair',
    imageSrc: Image15,
    description: 'Plateau en bois clair pour centre de table.',
    price: '3 €',
    dimensions: '5 x ø 32 cm'
  },
  {
    id: 16,
    category: 'Supports et Décoration de Table',
    name: 'Plateau bois ébène',
    imageSrc: Image16,
    description: 'Plateau en bois ébène pour centre de table.',
    price: '3 €',
    dimensions: '5 x ø 32 cm'
  },
  {
    id: 17,
    category: 'Supports et Décoration de Table',
    name: 'Plateau bois manguier',
    imageSrc: Image17,
    description: 'Plateau en bois de manguier pour centre de table.',
    price: '3 €',
    dimensions: '5,5 x ø 27 cm'
  },
  {
    id: 18,
    category: 'Vases et Soliflores',
    name: 'Soliflore blanc',
    imageSrc: Image18,
    description: 'Vase en céramique blanc mat.',
    price: '2 €',
    dimensions: 'Hauteurs : de 10 à 22 cm'
  },
  {
    id: 19,
    category: 'Vases et Soliflores',
    name: 'Soliflore taupe',
    imageSrc: Image19,
    description: 'Vase en céramique beige, blanc et taupe.',
    price: '1 €',
    dimensions: 'Hauteurs : de 7 à 13 cm'
  },
  {
    id: 20,
    category: 'Vases et Soliflores',
    name: 'Vase blanc',
    imageSrc: Image20,
    description: 'Vase en céramique blanc verni.',
    price: '2 €',
    dimensions: 'Hauteurs : de 13 à 26 cm'
  },
  {
    id: 21,
    category: 'Vases et Soliflores',
    name: 'Vase serpent',
    imageSrc: Image21,
    description: 'Vase en céramique blanc mat en forme de serpent.',
    price: '8 €',
    dimensions: '20 x 24 cm'
  },
  {
    id: 22,
    category: 'Vases et Soliflores',
    name: 'Soliflore rainuré',
    imageSrc: Image22,
    description: 'Petit vase cylindre à rainures.',
    price: '1 €',
    dimensions: 'Hauteur : 14,5 cm'
  },
  {
    id: 23,
    category: 'Vases et Soliflores',
    name: 'Soliflore rectangle',
    imageSrc: Image23,
    description: 'Vase soliflore rectangulaire en verre.',
    price: '1 €',
    dimensions: 'Hauteur : 16 cm'
  },
  {
    id: 24,
    category: 'Bougeoirs et Photophores',
    name: 'Bougeoir bubble',
    imageSrc: Image24,
    description: 'Bougeoir en verre effet “bulle”.',
    price: '10 €',
    dimensions: '26 cm x ø 10,5 cm'
  },
  {
    id: 25,
    category: 'Bougeoirs et Photophores',
    name: 'Bougeoir vert d\'eau',
    imageSrc: Image25,
    description: 'Bougeoir en céramique vert d’eau.',
    price: '2 €',
    dimensions: 'Hauteur : 13 cm'
  },
  {
    id: 26,
    category: 'Vases et Soliflores',
    name: 'Soliflore boule',
    imageSrc: Image26,
    description: 'Gros vase boule en verre.',
    price: '1 €',
    dimensions: 'Hauteur : 9,5 cm'
  },
  {
    id: 27,
    category: 'Vases et Soliflores',
    name: 'Soliflore boule 3',
    imageSrc: Image27,
    description: 'Petit vase boule à motif.',
    price: '1 €',
    dimensions: 'Hauteur : 9 cm'
  },
  {
    id: 28,
    category: 'Vases et Soliflores',
    name: 'Vase collorette',
    imageSrc: Image28,
    description: 'Vase collerette en verre.',
    price: '4 €',
    dimensions: '20 x 10,5 cm'
  },
  {
    id: 29,
    category: 'Vases et Soliflores',
    name: 'Vase coupe',
    imageSrc: Image29,
    description: 'Vase en forme de coupe.',
    price: '4 €',
    dimensions: 'Hauteur : 14 cm'
  },
  {
    id: 30,
    category: 'Vases et Soliflores',
    name: 'Vase collorette 2',
    imageSrc: Image30,
    description: 'Vase collerette en verre, taille moyenne.',
    price: '4 €',
    dimensions: '20 x 10,5 cm'
  },
  {
    id: 31,
    category: 'Panneaux et Backdrop',
    name: 'Love panneau bois',
    imageSrc: Image31,
    description: 'Panneau en bois clair avec le mot "Love".',
    price: '4 €',
    dimensions: '18 x 7 cm'
  },
  {
    id: 32,
    category: 'Console et Mobilier',
    name: 'Console en bois',
    imageSrc: Image32,
    description: 'Console en bois vintage avec tiroir.',
    price: '25 €',
    dimensions: '85 x 54 x 72 cm'
  }
];

export default PRODUCTS;
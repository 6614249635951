import React from 'react';
import '../css-styles/Galerie.scss';
import Header from '../components/Header';
import GalerieComponent from '../components/GalerieComponent'; 
import IMAGES from '../components/ImageGallery';
import "../css-styles/GalerieComponent.scss";
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';

export default function Galerie() {
  return (
    <div className="home-galerie">
      <Helmet>
        <title>Galerie Inspiration et décoration de mariage</title>
        <meta name="description" content="Explorez la galerie du Labo' Inspi pour une immersion dans l'élégance et l'originalité de nos décors de mariage." />
        <link rel="canonical" href="https://laboinspi-wedding.fr/galerie" />
      </Helmet>
      <Header />
      <div className='title-galerie-text'>
        <h1>Des scénographies originales pour votre mariage</h1>
        <h2 className='galerie-subtitle'>Le Labo’ Inspi vous propose une décoration naturelle, élégante et romantique</h2>
        <p>Si vous aimez le style bohème, champêtre, coloré, scandinave ou encore vintage pour la décoration de votre mariage, vous êtes au bon endroit.<br /> En tant que décoratrice, je m’inspire de ces codes et des tendances pour vous proposer une scénographie de mariage en accord avec vos envies.<br /> Des fleurs à la papeterie en passant par les objets déco, tout est choisi avec soin pour sublimer le plus beau jour de votre vie.</p>
      </div>
      <GalerieComponent images={IMAGES} /> 
      <Footer />
    </div>
  );
}
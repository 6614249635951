import React from "react";
import Header from "../components/Header.js";
import "../css-styles/Apropos.scss";
import "../css-styles/Home.scss";
import MyFormModal from "../components/MyFormModal.js";
import Formulaire from "../components/Formulaire.js";
import image from "../images/Pages/Marine Philippe- Morgane Caulle-21.webp";
import image2 from "../images/Pages/decoratricemariage-marinephilippe.webp";
import MyMapComponent from "../components/MyMapComponent.js";
import Footer from "../components/Footer.js";
import { Helmet } from "react-helmet";
import ImageMarievous from "../images/icone/MarieVous.jpg";

const Apropos = () => {
  return (
    <div className="home">
      <div className="home-container">
        <Helmet>
          <title>Wedding designer, votre décoratrice sur Pornic et Nantes</title>
          <meta
            name="description"
            content="Créatrice de décors de mariage sur mesure en Loire-Atlantique, je conçois des ambiances et scénographies unique pour rendre votre jour spécial inoubliable."
          />
          <link rel="canonical" href="https://laboinspi-wedding.fr/Apropos" />
        </Helmet>
        <Header />

        <div className="Background-about">
          <h1> VOTRE DÉCORATRICE SUR PORNIC ET NANTES</h1>
          <h2>
            Une scénographe et créatrice de souvenirs installée en
            Loire-Atlantique
          </h2>
          <div className="about-wrapper">
            <div className="about-container">
            <div className="Bio-text-up">
              <p>
                Moi c’est Marine, j’ai grandi en Normandie et je me suis toujours sentie chez moi en me baladant au bord de la mer. D’abord, débarquée à Saint-Nazaire pour le travail en tant que chef de projet événementiel, je suis tombée amoureuse des environs : Guérande, Pornic, Nantes et ses vignobles... 
              <br /> Une nature qui a le pouvoir de réveiller mon esprit créatif !
              </p>
              <div className="image-container">
                <img className="imageAbout-background" src={image} alt="Marine Philippe - Wedding designer Pornic" />
              </div>
            </div>

            
            <div className="certification-links-apropos">
        <a
          href="https://www.agence-mariella.com/post/un-shooting-mariage-dolce-vita-%C3%A0-gu%C3%A9rande"
          rel="dofollow"
          target="_blank"
          
        >
          <img
            className="certification-icone"
            src="https://static.wixstatic.com/media/b921ff_4776ed97d8c54cd8ad44c4e6ef122b7d~mv2.jpg/v1/fill/w_873,h_215,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/LOGO%20PRINCIPAL%20%5BJPG%5D%20HQ.jpg"
            alt="référence agence mariella"
            width="200"
            height="auto"
            loading="lazy"
          />
        </a>
        <a
          href="https://www.mariages.net/decoration-mariage/marine-wedding-designer--e303390"
          rel="dofollow"
          title="Mariages.net"
          target="_blank"
        >
          <img
            className="certification-icone2"
            alt="Mariages.net"
            src="https://www.mariages.net/images/sellos/label-partenaire--gg303390.png"
            style={{ borderWidth: 0 }}
            loading="lazy"
          />
        </a>
        <a
          href="https://www.mariezvous.fr/annuaire-prestataire-mariage/le-labo-inspi-wedding-designer/"
          rel="dofollow"
          title="mariezvous.fr"
          target="_blank"
        >
          <img
            className="certification-icone3"
            alt="https://www.mariezvous.fr/"
            src={ImageMarievous}
            style={{ borderWidth: 0 }}
            loading="lazy"
          />
        </a>
      </div>
            
            
            <div className="Bio-text-mid">
              <p>
                  Passionnée de décoration et romantique dans l’âme, j’ai toujours adoré créer des décors pour toutes les occasions.
                  <br></br>Dans l’organisation d’événements d’entreprise, le côté créatif me manquait. J’ai alors décidé de tenter l’aventure Mariella pour devenir Wedding Designer certifiée et diplômée.
              </p>
              <div className="image-container2">
                <img className="imageAbout-background2" src={image2} alt="Marine Philippe - Wedding designer Pornic" />
              </div>
            </div>

  

            <div class="quote-container">
              <span class="quote-icon">“</span>
              <p class="quote-text">
                Cette aventure en tant que décoratrice et wedding designer, c’est faire le métier dont je rêvais depuis toujours.
              </p>
            </div>

              <div className="findMe-text">
                <h3 className="element-title">
                  {" "}
                  Ma zone d’intervention en tant que décoratrice de mariage
                </h3>
                <p>
                Basée à Sainte-Pazanne, entre Pornic et Nantes, <br>
                </br>j’interviens en tant que décoratrice et wedding designer dans tout le département de la Loire-Atlantique (44) et la Vendée (85).
                </p>
              </div>
              <MyMapComponent />
            </div>
            
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Apropos;

import React, { useState } from 'react';
import PRODUCTS from './ProductsCatalog';
import '../css-styles/CatalogueComponent.scss';

const CatalogueComponent = () => {
  const [selectedCategory, setSelectedCategory] = useState('Toutes');

  // Filtrer les produits selon la catégorie sélectionnée
  const getFilteredProducts = () => {
    if (selectedCategory === 'Toutes') {
      return PRODUCTS;
    }
    return PRODUCTS.filter(product => product.category === selectedCategory);
  };

  return (
    <div>
      {/* Sélecteur de catégories */}
      <div className="category-selector">
        {['Toutes', 'Panneaux et Backdrop', 'Vases et Soliflores', 'Bougeoirs et Photophores', 'Supports et Décoration de Table', 'Lumières et Bougies', 'Autres'].map(category => (
          <button
            key={category}
            onClick={() => setSelectedCategory(category)}
            className={selectedCategory === category ? "active" : ""}
          >
            {category}
          </button>
        ))}
      </div>

      {/* Affichage des produits */}
      <div className="catalogue">
        {getFilteredProducts().map(product => (
          <div key={product.id} className="catalogue-item">
            <p>{product.name}</p>
            <img
              src={product.imageSrc}
              alt={product.name}
              loading="lazy"
            />
            <p className='catalogue-description'>{product.description}</p>
            <p className='catalogue-price'>Tarif unité : {product.price}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CatalogueComponent;
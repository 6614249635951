import React from 'react';
import PRODUCTS from '../components/ProductsCatalog'; // Remplacez par le bon chemin vers vos données
import Slider from 'react-slick';
import '../css-styles/CatalogPreview.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CatalogPreview = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Ajustez ce nombre pour afficher le nombre d'images souhaité sur une seule ligne.
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="catalog-preview">
      
      <Slider {...settings} className="product-slider">
        {PRODUCTS.map((product) => (
          <div key={product.id} className="product-card">
            <img src={product.imageSrc} alt={product.name} className="product-image" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CatalogPreview;
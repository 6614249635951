import React from 'react';
import { GoogleMap, Circle, LoadScript, Marker } from '@react-google-maps/api';

const citymap = {
  nantesCenter: {
    center: { lat: 47.1715, lng: -1.8891 }, // Centre approximatif entre Pornic, Nantes, Saint-Nazaire
  },
};

const containerStyle = {
  width: '100%',
  maxWidth: '480px',
  height: '380px',
};

// Coordonnées pour Sainte-Pazanne
const saintePazanne = {
  lat: 47.1075,
  lng: -1.8125,
};

const MyMapComponent = () => {
  return (
    <LoadScript googleMapsApiKey="AIzaSyC-PYXRuCwxqlV5OW-hUNn4qwFAf_NEcn0">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={citymap.nantesCenter.center}
        zoom={9}
        mapTypeId={"terrain"}
      >
        {Object.keys(citymap).map(city => (
          <Circle
            key={city}
            center={citymap[city].center}
            radius={40000} // Augmenter le rayon pour englober les trois villes
            options={{
              strokeColor: "#C0C0C0",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "#EFB49A",
              fillOpacity: 0.35,
            }}
          />
        ))}

        {/* Ajouter un marqueur pour Sainte-Pazanne */}
        <Marker 
          position={saintePazanne} 
          label="Sainte-Pazanne" 
        />
      </GoogleMap>
    </LoadScript>
  );
};

export default React.memo(MyMapComponent);
import React from 'react';
import { Link } from 'react-router-dom';
import Image1 from '../images/Pages/Formule/Cocktailshootingdolcevita-sarahduguephotographe85Small.webp';
import Image2 from '../images/Pages/Formule/bouquetmariée-anaisduchesnephotographieSmall.webp';
import Image3 from '../images/Pages/Formule/salleshootingdolcevita-sarahduguephotographe110Small.webp';
import '../css-styles/PreviewFormules.scss';

const PreviewFormules = () => {
  return (
    <div className='Title-formule-wrapper'>
      <h2 className="title">Les formules</h2>
      <div className="formules-wrapper">
        <div className="formule-element coaching">
          <img
            src={Image1}
            className="formule-image"
            alt="Coaching décoration"
            loading="lazy"
          />
          <div className="formule-text-wrapper">
            <h3>COACHING</h3>
            <p>Les clés pour débuter dans votre projet et des conseils précieux sur la partie créative et le choix des prestataires.</p>
            <p className='quote-price' >à partir de <span className='Price-formule'>350 €</span></p>
            <Link to="/Coaching" className='Ref-formule'>
              <div className="plus-icon">+</div>
            </Link>
          </div>
        </div>

        <div className="formule-element preparatifs">
          <img
            src={Image2}
            className="formule-image"
            alt="Préparatifs décoration"
            loading="lazy"
          />
          <div className="formule-text-wrapper">
            <h3>PRÉPARATIFS</h3>
            <p>Conception et coordination de toute votre décoration en amont et les clés pour installer votre décoration le jour J.</p>
            <p className='quote-price'>à partir de <span className='Price-formule'>900 €</span></p>
            <Link to="/Preparatifs" className='Ref-formule'>
              <div className="plus-icon">+</div>
            </Link>
          </div>
        </div>

        <div className="formule-element cle-en-main">
          <img
            src={Image3}
            className="formule-image"
            alt="Clé en main décoration"
            loading="lazy"
          />
          <div className="formule-text-wrapper">
            <h3>CLÉ EN MAIN</h3>
            <p>Conception sur mesure, coordination des prestataires et installation complète de votre décoration sur place.</p>
            <p className='quote-price'>à partir de <span className='Price-formule'>1500 €</span></p>
            <Link to="/Cle-en-main" className='Ref-formule'>
              <div className="plus-icon">+</div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PreviewFormules;
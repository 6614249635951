import React from "react";
import Header from "../components/Header";
import Hero from "../components/Hero";
import BodyHome from "../components/BodyHome";
import Footer from "../components/Footer"

import '../css-styles/Home.scss';
import '../css-styles/Main.scss';
import '../css-styles/Responsive.scss';
import '../css-styles/Footer.scss';
import BackGround from "../components/BackGround";
import Certifications from "../components/Certifications";
import FormContact from "../components/FormContact";
import { Helmet } from 'react-helmet';
import AvisClients from "../components/AvisClients";
import Title from "../components/Title";
import WeddingDesigner from "../components/WeddingDesigner";
import PreviewFormules from "../components/PreviewFormules";
import Decoration from "../components/Decoration";
import Papeterie from "../components/Papeterie";
import MarineBio from "../components/MarineBio";
import BackgroundImage from "../components/BackgroundImage";


function Home() {
 

  return (
    <div className="home" >
      
        <Helmet>
          <title>Décoratrice de Mariage à Nantes, Pornic, Saint-Nazaire – Créations sur Mesure</title>
          <meta name="description" content="Décoratrice de mariage à Nantes, Pornic et Saint-Nazaire. Spécialiste en décoration sur mesure pour un mariage élégant et personnalisé. "></meta>
          <link rel="canonical" href="https://laboinspi-wedding.fr/" />
        </Helmet>
        <Header/>
        <Title />
        <div className="home-container">
          <WeddingDesigner />
          <PreviewFormules />
          <Decoration />
          <Papeterie />
          <MarineBio />
      </div>
        
        <AvisClients />
        <Certifications/>
        <BackgroundImage />
        <Footer/>
        
    </div>
  );
}

export default Home;
import React from 'react';
import Image from '../images/Pages/salleshootingdolcevita-sarahduguephotographe95.webp'; // Remplace par le bon chemin de l'image
import '../css-styles/Decoration.scss';

const Decoration = () => {
  return (
    <div className="decoration-wrapper">
      <div className="photo-container">
        <img
          src={Image}
          alt="Décoration de table"
          className="decoration-image"
        />
      </div>
      <div className="decoration-container">
        <h2 className="element-title">Décoration</h2>
        <div className="decoration-text">
          <p>
            Une large gamme d'
            <span className="colored-text">objets de décoration simple, moderne et élégants</span>, pour sublimer votre lieu de réception : panneau de bienvenue, plan de table, espace urne, photobooth, décoration de table, décoration de salle...
          </p>
          <p>
            Une prise en charge complète de <span className="colored-text">l'installation de votre décoration</span> la veille ou le jour J.
          </p>
        </div>
        <a href="/Decoration" className="ma-formule-button">Ma Formule</a> {/* Bouton centré */}
      </div>
    </div>
  );
}

export default Decoration;